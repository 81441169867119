import { AbilityBuilder, Ability } from '@casl/ability';

export const AppAbility = Ability;

export default function defineRulesFor(user) {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // Types of users: teacher, student, relative
  const roles = user?.roles?.map((role) => {
    return role.code;
  });

  // Positions of user
  const positions = user?.positions?.map((position) => {
    return position.code;
  });

  can('manage', 'Profile', user?.uuid);
  can('manage', 'TelegramID', user?.uuid);
  can('update', 'ChecklistGoal', user?.uuid);

  if (user?.is_ktl) {
    can('access', 'BILStatus');
  }

  if (roles?.includes('STUDENT')) {
    cannot('manage', 'TeacherProfile');
    cannot('update', 'Profile');
    can('read', 'ExaminationResult');
    can('manage', 'Dashboard');
    can('read', 'StudentSkillsPage');
    can('read', 'Psychology');
    // can('read', 'UNT');
  }

  if (
    positions?.includes('C') ||
    positions?.includes('NRC') ||
    positions?.includes('SRC') ||
    positions?.includes('WRC') ||
    positions?.includes('ERC') ||
    positions?.includes('MRC')
  ) {
    can('read', 'all');
    cannot('read', 'Dashboard');
    can('update', 'ActivePosition');
    can('update', 'BILStatus');
    can('manage', 'Teachers');
    can('create', 'Students');
    can('manage', 'Students');
    can('manage', 'Grades');
    // can('manage', 'Transfers');
    can('manage', 'Domains');
    can('update', 'TransferPosition');
    can('manage', 'Schools');
    can('manage', 'TciCoordinatorModal');
    can('update', 'TciEvaluateModalButton');
    can('manage', 'ExaminationSettings');
    can('read', 'SchoolsExamination');
    can('read', 'TciOverview');
    cannot('read', 'TciProfile', user?.uuid);
    cannot('create', 'Teachers');
    cannot('read', 'SimulationButton');
    can('read', 'ExaminationResult');
    can('manage', 'ExaminationUpload');
    can('read', 'TatExcel');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    can('create', 'GenerateChecklist');
    can('manage', 'AdminSkillsPage');
    can('read', 'Accounting');
    cannot('read', 'StudentSkillsPage');
    can('create', 'Announcement');
    can('createForTeachers', 'Announcement');
    can('createForAll', 'Announcement');
    can('filter', 'Region');
    can('read', 'OlympiadReportForCoordinator');
    can('read', 'TarbieCoordinatorReport');
    // temporary
    can('read', 'BTSResults');
    can('read', 'AcademicGoals');
    can('read', 'TATResults');
    can('read', 'AcademicGoalsOverview');
    can('read', 'Calendar');
    can('read', 'OlympiadResults');
    can('update', 'RotationTransfer');
    can('read', 'TeacherCategory');
    can('update', 'KboFinal');
    can('filter', 'KboFinal');
    can('manage', 'Psychology');
    can('read', 'Psychology');
    can('transfer', 'Students');
    can('read', 'UNT');
    can('read', 'ExamReports');
    can('manage', 'ExamReports');
  }
  if (positions?.includes('HRC')) {
    can('read', 'all');
    cannot('read', 'Dashboard');
    can('manage', 'Grades');
    can('manage', 'Accounts');
    can('update', 'ActivePosition');
    can('update', 'BILStatus');
    can('manage', 'Teachers');
    can('export', 'HrExcel');
    can('export', 'TeachersPasswords');
    can('create', 'Students');
    can('manage', 'Students');
    can('manage', 'Transfers');
    can('manage', 'Domains');
    can('update', 'TransferPosition');
    can('manage', 'Schools');
    can('manage', 'TciCoordinatorModal');
    can('manage', 'ExaminationSettings');
    can('read', 'SchoolsExamination');
    can('read', 'ExaminationResult');
    cannot('read', 'TciProfile', user?.uuid);
    can('manage', 'Comments');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    can('update', 'SimulationModule');
    can('create', 'GenerateChecklist');
    cannot('read', 'StudentSkillsPage');
    can('create', 'Announcement');
    can('createForTeachers', 'Announcement');
    can('createForAll', 'Announcement');
    can('filter', 'Region');
    can('read', 'OlympiadReportForCoordinator');
    can('read', 'TarbieCoordinatorReport');
    // temporary
    can('read', 'BTSResults');
    can('read', 'TATResults');
    can('manage', 'Accounting');
    can('update', 'TciEvaluateModalButton');
    can('update', 'RotationTransfer');
    can('read', 'TeacherCategory');
    can('update', 'KboFinal');
    can('filter', 'KboFinal');
    can('manage', 'Psychology');
    can('read', 'Psychology');
    can('transfer', 'Students');
    can('read', 'ExamReports');
    can('manage', 'ExamReports');
  }
  if (positions?.includes('STDC')) {
    can('manage', 'ClassGrade');
    can('read', 'Students');
    can('manage', 'AcceptStudents');
    can('manage', 'Students');
    can('create', 'Students');
    can('manage', 'TransferStudent');
    can('create', 'Grades');
    can('manage', 'Grades');
    can('manage', 'ExportStudents');
  }
  if (positions?.includes('PRNC')) {
    // can('read', 'all');
    can('update', 'ActivePosition');
    can('manage', 'Teachers');
    can('export', 'TeachersPasswords');
    can('read', 'Students');
    can('manage', 'Students');
    can('create', 'Students');
    can('manage', 'TransferStudent');
    can('create', 'Grades');
    can('manage', 'Grades');
    // can('manage', 'Transfers');
    can('manage', 'Domains');
    can('manage', 'Schools');
    can('manage', 'TciOverview');
    can('read', 'TciStats');
    cannot('update', 'BILStatus');
    cannot('read', 'SchoolsList');
    cannot('read', 'TciTeacherEvaluationsCount');
    can('read', 'TciProfile');
    can('read', 'ExaminationResult');
    can('manage', 'ExaminationUpload');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    cannot('read', 'SimulationButton');
    can('create', 'GenerateChecklist');
    can('update', 'ChecklistGoal');
    can('read', 'Accounting');
    can('create', 'Announcement');
    can('createForTeachers', 'Announcement');
    can('reads', 'OlympiadReportForSchool');
    can('reads', 'TarbieSchoolReport');
    can('read', 'AcademicGoals');
    can('read', 'TATResults');
    can('update', 'TciEvaluateModalButton');
    can('read', 'OlympiadResults');
    can('update', 'RotationTransfer');
    can('read', 'TeacherCategory');
    can('filter', 'KboFinal');
    can('manage', 'Psychology');
    can('read', 'Psychology');
    can('transfer', 'Students');
    can('read', 'UNT');
    can('read', 'ExamReports');
    can('update', 'ExamReports');
  }
  if (positions?.includes('VPD')) {
    // can('read', 'all');
    can('update', 'ActivePosition');
    can('manage', 'Teachers');
    can('read', 'Students');
    can('manage', 'Students');
    can('create', 'Students');
    can('manage', 'TransferStudent');
    can('create', 'Grades');
    can('manage', 'Grades');
    // can('manage', 'Transfers');
    can('manage', 'Domains');
    can('manage', 'Schools');
    can('manage', 'TciOverview');
    can('read', 'TciStats');
    cannot('update', 'BILStatus');
    cannot('read', 'SchoolsList');
    cannot('read', 'TciTeacherEvaluationsCount');
    can('read', 'TciProfile');
    can('read', 'ExaminationResult');
    can('manage', 'ExaminationUpload');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    cannot('read', 'SimulationButton');
    can('create', 'GenerateChecklist');
    can('update', 'ChecklistGoal');
    can('create', 'Announcement');
    can('createForTeachers', 'Announcement');
    can('reads', 'OlympiadReportForSchool');
    can('reads', 'TarbieSchoolReport');
    can('read', 'TATResults');
    can('read', 'AcademicGoals');
    can('read', 'OlympiadResults');
    cannot('update', 'RotationTransfer');
    can('read', 'TeacherCategory');
    can('filter', 'KboFinal');
    can('manage', 'Psychology');
    can('read', 'Psychology');
    can('transfer', 'Students');
    can('read', 'UNT');
    can('read', 'ExamReports');
    can('update', 'ExamReports');
  }
  if (positions?.includes('VPC')) {
    // can('read', 'all');
    can('update', 'ActivePosition');
    can('manage', 'Teachers');
    can('read', 'Students');
    can('manage', 'Students');
    can('create', 'Students');
    can('manage', 'TransferStudent');
    can('create', 'Grades');
    can('manage', 'Grades');
    // can('manage', 'Transfers');
    can('manage', 'Domains');
    can('manage', 'Schools');
    can('manage', 'TciOverview');
    can('manage', 'TciEvaluateTeacher');
    can('read', 'TciStats');
    cannot('update', 'BILStatus');
    cannot('read', 'SchoolsList');
    cannot('read', 'TciTeacherEvaluationsCount');
    can('read', 'TciProfile');
    can('read', 'ExaminationResult');
    can('manage', 'ExaminationUpload');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    cannot('read', 'SimulationButton');
    can('create', 'GenerateChecklist');
    can('update', 'ChecklistGoal');
    can('read', 'Accounting');
    can('create', 'Announcement');
    can('createForTeachers', 'Announcement');
    can('reads', 'OlympiadReportForSchool');
    can('reads', 'TarbieSchoolReport');
    can('read', 'AcademicGoals');
    can('read', 'TATResults');
    can('read', 'OlympiadResults');
    cannot('update', 'RotationTransfer');
    can('read', 'TeacherCategory');
    can('filter', 'KboFinal');
    can('manage', 'Psychology');
    can('read', 'Psychology');
    can('transfer', 'Students');
    can('read', 'UNT');
    can('read', 'ExamReports');
    can('update', 'ExamReports');
  }
  if (positions?.includes('VPSA')) {
    // can('read', 'all');
    can('update', 'ActivePosition');
    can('manage', 'Teachers');
    can('read', 'Students');
    can('manage', 'Students');
    can('create', 'Students');
    can('manage', 'TransferStudent');
    can('create', 'Grades');
    can('manage', 'Grades');
    // can('manage', 'Transfers');
    can('manage', 'Domains');
    can('manage', 'Schools');
    can('manage', 'TciOverview');
    can('manage', 'TciEvaluateTeacher');
    can('read', 'TciStats');
    cannot('update', 'BILStatus');
    cannot('read', 'SchoolsList');
    cannot('read', 'TciTeacherEvaluationsCount');
    can('read', 'TciProfile');
    can('read', 'ExaminationResult');
    can('manage', 'ExaminationUpload');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    cannot('read', 'SimulationButton');
    can('create', 'GenerateChecklist');
    can('update', 'ChecklistGoal');
    can('read', 'Accounting');
    can('create', 'Announcement');
    can('createForTeachers', 'Announcement');
    can('reads', 'OlympiadReportForSchool');
    can('reads', 'TarbieSchoolReport');
    can('read', 'AcademicGoals');
    can('read', 'TATResults');
    can('read', 'OlympiadResults');
    cannot('update', 'RotationTransfer');
    can('read', 'TeacherCategory');
    can('filter', 'KboFinal');
    can('manage', 'Psychology');
    can('read', 'Psychology');
    can('transfer', 'Students');
    can('read', 'UNT');
    can('read', 'ExamReports');
    can('update', 'ExamReports');
  }
  if (
    positions?.includes('2') // Olympiad coordinator of school
  ) {
    can('read', 'Domains');
    can('read', 'Subdomains');
    can('manage', 'TciOverview');
    can('read', 'TciProfile', user?.uuid);
    can('read', 'ExaminationResult');

    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');

    can('read', 'Accounting');
    can('create', 'Announcement');
    can('reads', 'OlympiadReportForSchool');
    can('filter', 'KboFinal');
    can('read', 'OlympiadResults');
    can('read', 'ExamReports');
  }
  if (
    positions?.includes('T') &&
    roles?.includes('TEACHER') &&
    !positions.includes('C') &&
    !positions.includes('VPC') &&
    !positions.includes('VP') &&
    !positions.includes('PRNC') &&
    !positions?.includes('2')
  ) {
    can('read', 'Domains');
    can('read', 'Subdomains');
    can('manage', 'TciOverview');
    cannot('update', 'ActivePosition');
    cannot('read', 'BILStatus');
    cannot('read', 'Schools');
    cannot('read', 'TciTeacherEvaluationsCount');
    can('read', 'TciProfile', user?.uuid);
    can('read', 'ExaminationResult');
    cannot('manage', 'ExaminationUpload');
    can('manage', 'TeacherProfile');
    can('update', 'StudentPosition');
    cannot('read', 'SimulationButton');
    can('read', 'Accounting');
    can('create', 'Announcement');
    can('filter', 'KboFinal');
    can('create', 'GenerateChecklist');
    can('read', 'UNT');
    can('read', 'ExamReports');
  }

  if (roles?.includes('TEACHER')) {
    can('read', 'Accounting');
  }

  if (positions?.includes('TJ')) {
    can('read', 'Accounting');
  }

  if (roles?.includes('FF')) {
    can('read', 'Accounting');
    can('manage', 'Accounting');
  }

  if (positions?.includes('FS')) {
    can('read', 'Accounting');
    can('manage', 'Accounting');
  }

  if (positions?.includes('OT')) {
    can('reads', 'OlympiadReportForTeachers');
  }

  if (positions?.includes('3')) {
    // Home room teacher / класс рук
    can('reads', 'TarbieTeacherReport');
    can('read', 'Students');
  }

  if (positions?.includes('PSCH')) {
    can('read', 'Psychology');
    can('manage', 'Psychology');
  }

  if (positions?.includes('PSCH-M')) {
    can('read', 'Psychology');
    can('manage', 'Psychology');
  }

  if (positions?.includes('SASA')) {
    can('read', 'Students');
    can('manage', 'Students');
    can('create', 'Students');
    can('manage', 'TransferStudent');
    can('create', 'Grades');
    can('manage', 'Grades');
  }
  if (positions?.includes('PRT')) {
    can('read', 'ExaminationResult');
  }

  if (roles?.includes('PARENT')) {
    can('read', 'ExaminationResult');
  }

  return rules;
}

export function buildAbilityFor(user) {
  return new AppAbility(defineRulesFor(user), {});
}
