import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import React, { lazy, useEffect, useState } from 'react';
import { UserContext } from './utils/contexts/UserContext';

import Scaffold from './components/Scaffold/Scaffold';

// Examinations imports

import { buildAbilityFor } from './utils/ability';
import { AbilityContext } from './utils/Can';
import { I18nextProvider } from 'react-i18next';
import i18n from './lang/i18n';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/kk';
import useFindUser from './utils/hooks/useFindUser';
import PrivateRoute from './components/routing/PrivateRoute/PrivateRoute';
import SnackbarProvider from './utils/contexts/SnackbarProvider';
import SimpleLoader from './components/Loader/SimpleLoader';
import { ChildrenContext } from './utils/contexts/ChildrenContext';

const LazyLoginPage = lazy(() => import('./pages/Login/LoginPage/LoginPage'));
const LazyProfile = lazy(() =>
  import('./pages/Profile/ProfilePage/ProfilePage'),
);
const LazyDashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const LazyExaminations = lazy(() => import('./pages/Examinations/Main'));
const LazyBtsMain = lazy(() => import('./pages/Examinations/Bts/Main'));
const LazyBtsResult = lazy(() =>
  import('./pages/Examinations/Bts/Result/Result'),
);
const LazyUntMain = lazy(() => import('./pages/Unt/Main'));
const LazyUntResult = lazy(() => import('./pages/Unt/Result/Result'));
const LazyPetMain = lazy(() => import('./pages/Examinations/KetPet/Main'));
const LazyPetResult = lazy(() =>
  import('./pages/Examinations/KetPet/Result/Result'),
);
const LazyTatMain = lazy(() => import('./pages/Examinations/Tat/Main'));
const LazyTatResult = lazy(() =>
  import('./pages/Examinations/Tat/Result/ResultViaPath'),
);
const LazyTranscript = lazy(() =>
  import('./pages/Examinations/Transcript/Transcript'),
);
const LazyKboMain = lazy(() => import('./pages/Examinations/Kbo/Main'));
const LazyKboFinal = lazy(() => import('./pages/Examinations/KboFinal/Main'));
const LazyKboResult = lazy(() =>
  import('./pages/Examinations/Kbo/Result/Result'),
);
const LazyStudentsMain = lazy(() => import('./pages/Students/Main'));
const LazyStudents = lazy(() => import('./pages/Students/Students/Students'));
const LazyNotFound = lazy(() => import('./pages/NotFound/NotFound'));
const LazySchoolsPage = lazy(() =>
  import('./pages/Schools/SchoolsPage/SchoolsPage'),
);
const LazySchoolProfile = lazy(() =>
  import('./pages/Schools/SchoolProfilePage/SchoolProfile'),
);
const LazyTransfersPage = lazy(() =>
  import('./pages/Transfers/TransfersPage/TransfersPage'),
);
const LazyTciStatsPage = lazy(() =>
  import('./pages/Tci/TciStatsPage/TciStatsPage'),
);
// const LazyTciEvaluationForm = lazy(() =>
//   import('./pages/Tci/TciEvaluationForm/TciEvaluationForm'),
// );
// const LazyNewTciEvaluationForm = lazy(() =>
//   import('./pages/Tci/TciEvaluationForm/NewTciEvaluationForm'),
// );
const LazyNewTciEvaluationForm = lazy(() =>
  import('./pages/Tci/TciEvaluationForm/TciEvaluationQuiz'),
);

const LazyTciViewEvaluations = lazy(() =>
  import('./pages/Tci/TciViewEvaluations/TciViewEvaluations'),
);
const LazyTciTeachersRating = lazy(() =>
  import('./pages/Tci/TciTeachersRating/TciTeachersRating'),
);
const LazyAdminSkillsPage = lazy(() =>
  import('./pages/Skills/AdminPage/AdminSkillsPage'),
);
const LazyStudentSkillsPage = lazy(() =>
  import('./pages/Skills/StudentSkillsPage/StudentSkillsPage'),
);
const LazyStudentViewSkillPage = lazy(() =>
  import('./pages/Skills/StudentViewSkillPage/StudentViewSkillPage'),
);
const LazyStudentViewSectionPage = lazy(() =>
  import('./pages/Skills/StudentViewSectionPage/StudentViewSectionPage'),
);
const LazyEditSkill = lazy(() => import('./pages/Skills/EditSkill/EditSkill'));
const LazyEditLesson = lazy(() =>
  import('./pages/Skills/EditLesson/EditLesson'),
);
const LazyForm = lazy(() => import('./pages/Accounting/Form/Form'));
const LazyTeacherDocs = lazy(() =>
  import('./pages/Accounting/TeacherList/TeacherDocs/TeacherDocs'),
);
const LazyAccounting = lazy(() => import('./pages/Accounting/Accounting'));
const LazyTeacherTabs = lazy(() =>
  import('./pages/Accounting/TeacherList/TeacherTabs'),
);
const LazyMyForm = lazy(() => import('./pages/Accounting/Form/MyForm'));
const LazyQundylyqMain = lazy(() => import('./pages/Qundylyq/Main'));
const LazyQundylyqList = lazy(() => import('./pages/Qundylyq/List/List'));
const LazyQundylyqSchedule = lazy(() =>
  import('./pages/Qundylyq/Schedule/Schedule'),
);
const LazyQundylyqTests = lazy(() => import('./pages/Qundylyq/Tests/Tests'));
const LazyAnnouncementFeed = lazy(() =>
  import('./pages/Announcement/AnnouncementFeed/AnnouncementFeed'),
);
const LazyCreateAnnouncement = lazy(() =>
  import('./pages/Announcement/CreateAnnouncement/CreateAnnouncement'),
);
const LazyAnnouncementSent = lazy(() =>
  import('./pages/Announcement/AnnouncementSent/AnnouncementSent'),
);
const LazyAnnouncementPostPage = lazy(() =>
  import('./pages/Announcement/AnnouncementPostPage'),
);
const LazyCalendarMain = lazy(() => import('./pages/Calendar/Main'));
const LazyOlympiadMain = lazy(() => import('./pages/Olympiad/Main'));
const LazyOlympiadReportMenu = lazy(() =>
  import('./pages/Olympiad/Report/OlympiadReportMenu'),
);
const LazyChecklistPage = lazy(() => import('./pages/Checklist/ChecklistPage'));
const LazyAcademicGoals = lazy(() => import('./pages/AcademicGoals/Main'));
const LazySettings = lazy(() => import('./pages/Settings/Settings'));
const LazySchoolList = lazy(() => import('./pages/Students/Schools/Schools'));
const LazyTarbieReportMain = lazy(() => import('./pages/Tarbie/Report/Main'));
const LazyResetPassword = lazy(() =>
  import('./pages/ResetPassword/ResetPassword'),
);
const LazyInstructionsPage = lazy(() =>
  import('./pages/Instructions/InstructionsPage'),
);
const LazyInstructionPage = lazy(() =>
  import('./pages/Instructions/InstroductionPage'),
);
const LazyPedagogicalLevel = lazy(() =>
  import('./pages/PedagogicalLevel/PedagogicalLevel'),
);
const LazyPsychologyMain = lazy(() => import('./pages/Psychology/Main'));
const LazyPsychologyStatsSchools = lazy(() =>
  import('./pages/Psychology/Stats/Schools/Schools'),
);
const LazyPsychologyStatsSchool = lazy(() =>
  import('./pages/Psychology/Stats/School/School'),
);
const LazyPsychologyStatsGrade = lazy(() =>
  import('./pages/Psychology/Stats/Grade/Grade'),
);
const LazyTeacherMain = lazy(() => import('./pages/Teachers/TeachersMain'));
const LazyMain = lazy(() => import('./pages/Tci_v2/Page/Main'));
const LazySimulationMain = lazy(() =>
  import('./pages/TransferSimulations/Main'),
);
const LazySchoolGrades = lazy(() =>
  import('./pages/Students/SchoolGrades/SchoolGrades'),
);
const LazySSOPPage = lazy(() => import('./pages/Login/SSOP/SSOPPage'));
const LazyAccounts = lazy(() => import('./pages/Accounts/Accounts'));
const LazyUnt = lazy(() => import('./pages/Unt/Unt'));

// const LazyParentDashboard = lazy(() => import('./pages/Parent/Dashboard'));
const LazyPrincipalBIFTransfers = lazy(() =>
  import('./pages/Teachers/BIFTransfers/PrincipalBIFTransfers'),
);

const LazyExamReports = lazy(() => import('./pages/ExamReports/Main'));

function App() {
  const { user, setUser, roles, positions, isLoading } = useFindUser();
  const [currentChild, setCurrentChild] = useState();
  const [children, setChildren] = useState([]);
  const ability = buildAbilityFor(user);

  useEffect(() => {
    moment.locale(localStorage.getItem('i18nextLng'));
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <UserContext.Provider
          value={{ user, setUser, roles, positions, isLoading }}
        >
          <ChildrenContext.Provider
            value={{ children, setChildren, currentChild, setCurrentChild }}
          >
            <AbilityContext.Provider value={ability}>
              <SnackbarProvider>
                <Switch>
                  <Route
                    exact
                    path='/login'
                    component={() => (
                      <React.Suspense
                        fallback={<SimpleLoader className='h-6' />}
                      >
                        <LazyLoginPage />
                      </React.Suspense>
                    )}
                  />
                  <Route
                    exact
                    path='/ssop'
                    component={() => (
                      <React.Suspense
                        fallback={<SimpleLoader className='h-6' />}
                      >
                        <LazySSOPPage />
                      </React.Suspense>
                    )}
                  />
                  <Route
                    exact
                    path='/reset-password'
                    component={() => (
                      <React.Suspense
                        fallback={<SimpleLoader className='h-6' />}
                      >
                        <LazyResetPassword />
                      </React.Suspense>
                    )}
                  />
                  <Scaffold user={user} setUser={setUser}>
                    <Switch>
                      <PrivateRoute
                        exact
                        path='/'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyDashboard />
                          </React.Suspense>
                        )}
                      >
                        <Redirect
                          to={
                            // user?.roles?.some((r) => r?.code === 'PARENT')
                            //   ? `/parent/dashboard/${user?.uuid}`
                            //   :
                            `/dashboard/${user?.uuid}`
                          }
                        />
                      </PrivateRoute>

                      <PrivateRoute
                        exact
                        path='/accounts'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAccounts />
                          </React.Suspense>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path='/teachers'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTeacherMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/teachers/:secret'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTeacherMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/bif-transfers'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <div className='p-6'>
                              <LazyPrincipalBIFTransfers />
                            </div>
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/bif-transfers/:secret'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <div className='p-6'>
                              <LazyPrincipalBIFTransfers />
                            </div>
                          </React.Suspense>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path='/students'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyStudentsMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/students/schools/:uuid/grades'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazySchoolGrades />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/students/schools'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazySchoolList user={user} />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/exam_reports'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyExamReports user={user} />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/unt'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyUnt />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/students/schools/:schoolUuid/grades/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyStudents />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/psychology'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPsychologyMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/psychology/stats/schools'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPsychologyStatsSchools />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/psychology/stats/schools/:schoolUuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPsychologyStatsSchool />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/psychology/stats/schools/:schoolUuid/grades/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPsychologyStatsGrade />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/transfers'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTransfersPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/profile'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyProfile />
                          </React.Suspense>
                        )}
                      >
                        <Redirect to={`/profile/${user?.uuid}`} />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path='/dashboard/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyDashboard />
                          </React.Suspense>
                        )}
                      />
                      {/* <PrivateRoute
                        exact
                        path='/parent/dashboard/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyParentDashboard />
                          </React.Suspense>
                        )}
                      /> */}
                      <PrivateRoute
                        exact
                        path='/profile/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyProfile />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/schools'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazySchoolsPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/schools/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazySchoolProfile />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/tci/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/tci/evaluate/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyNewTciEvaluationForm />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/tci/view/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTciViewEvaluations />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/tci/stats/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTciStatsPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/tci/stats/rating/teachers'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTciTeachersRating />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/transfers/simulation'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazySimulationMain />
                          </React.Suspense>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path='/admin/skills'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAdminSkillsPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/admin/skills/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyEditSkill />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/admin/lessons/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyEditLesson />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/skills'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyStudentSkillsPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/skills/sections/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyStudentViewSectionPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/skills/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyStudentViewSkillPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyExaminations />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/accounting/'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAccounting />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/accounting/form/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyForm />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/accounting/my_form/'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyMyForm />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/accounting/teachers'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTeacherTabs />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/accounting/teachers/docs/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTeacherDocs />
                          </React.Suspense>
                        )}
                      />
                      {/* <PrivateRoute
                        exact
                        path='/qundylyq'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyQundylyqMain />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/qundylyq/list'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyQundylyqList />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/qundylyq/schedule'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyQundylyqSchedule />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/qundylyq/tests'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyQundylyqTests />
                          </React.Suspense>
                        )}
                        user={user}
                      /> */}
                      <PrivateRoute
                        exact
                        path='/academic_goals'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAcademicGoals />
                          </React.Suspense>
                        )}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/kbo/:year/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyKboMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/kbo-final/:year/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyKboFinal />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/kbo/:year/:examUuid/transcript/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyKboResult />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/unt/:examUuid/transcript/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyUntResult />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/bts/:year/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyBtsMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/bts/:year/:examUuid/transcript/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyBtsResult />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/:year/transcript/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTranscript />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/pet/:year/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPetMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/pet/:year/:examUuid/transcript/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPetResult />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/tat/:year/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTatMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/examinations/tat/:year/:examUuid/transcript/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTatResult />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/unt/:year/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyUntMain />
                          </React.Suspense>
                        )}
                      />
                      {/* Add other routes here */}
                      <PrivateRoute
                        exact
                        path='/checklist'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyChecklistPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/calendar'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyCalendarMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/announcement/feed'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAnnouncementFeed />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/announcement/create'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyCreateAnnouncement />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/announcement/sent'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAnnouncementSent />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/announcement/post/:uuid'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyAnnouncementPostPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/olympiadResults'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyOlympiadMain />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/olympiad/report'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyOlympiadReportMenu />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/settings'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazySettings />
                          </React.Suspense>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path='/tarbie/report'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyTarbieReportMain />
                          </React.Suspense>
                        )}
                      />

                      <Route
                        exact
                        path='/instructions/:lessonUUID'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyInstructionPage />
                          </React.Suspense>
                        )}
                      />
                      <Route
                        exact
                        path='/instructions'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyInstructionsPage />
                          </React.Suspense>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path='/pedagogical-level'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyPedagogicalLevel />
                          </React.Suspense>
                        )}
                      />
                      <Route
                        path='*'
                        component={() => (
                          <React.Suspense
                            fallback={<SimpleLoader className='h-6' />}
                          >
                            <LazyNotFound />
                          </React.Suspense>
                        )}
                      />
                    </Switch>
                  </Scaffold>
                </Switch>
              </SnackbarProvider>
            </AbilityContext.Provider>
          </ChildrenContext.Provider>
        </UserContext.Provider>
      </Router>
    </I18nextProvider>
  );
}

export default App;
